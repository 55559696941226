<template>
    <div class="dashboard">
        <div class="wrapper">
            <div class="h1">Добавление интересов</div>

            <div class="underline mb-4">
                <interest-form :isEdit="false" @updateInterest="updateInterest"/>
            </div>

            <div class="h3 mb-3">Список интересов</div>

            <div class="auto-scroll-horizontal">
                <b-table
                    :busy="isBusy"
                    :fields="fields"
                    :items="interestsProvider"
                    class="mb-0"
                    :current-page="currentPage"
                    :per-page="perPage"
                    empty-text="Нет доступных интересов"
                    show-empty
                    ref="interestsTable"
                >

                    <template #cell(edit)="data">
                        <button class="btn-crl" @click="handleEdit(data.item)">
                            <b-icon icon="pencil-fill"></b-icon>
                        </button>
                    </template>

                    <template #cell(delete)="data">
                        <button class="btn-crl" @click="handleDelete(data.item._id)">
                            <b-icon icon="x-circle-fill"></b-icon>
                        </button>
                    </template>

                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                    </template>

                    <template #empty="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>

                    <template #emptyfiltered="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>
                </b-table>

                <edit-interest :editData="editData" @editInterest="editInterest"/>
            </div>

            <div v-if="totalInterests > perPage" class="pt-3">
                <b-pagination first-number last-number :total-rows="totalInterests" :per-page="perPage" v-model="currentPage"/>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'
    import InterestForm from '@/components/forms/interests/InterestForm'
    import EditInterest from "@/components/modals/EditInterest";

    export default {
        name: "interests",
        components: {
            EditInterest,
            InterestForm
        },
        methods: {
            ...mapActions([
                'getInterests',
                'deleteInterests'
            ]),
            updateInterest(){
                this.$refs.interestsTable.refresh();
            },
            editInterest(interestApi){
                for (let i = 0, len = this.interests.length; i < len; i++) {
                    const interest = this.interests[i];
                    if(interest._id === interestApi._id){
                        Object.assign(this.interests[i], interestApi);
                        break;
                    }
                }
                this.$bvModal.hide('edit-interest');
            },
            interestsProvider(ctx){
                const data = {
                    page: ctx.currentPage,
                };

                if(ctx.sortBy){
                    data.sortBy = ctx.sortBy;
                    data.sort = ctx.sortDesc ? 'desc' : 'asc';
                }

                return this.getInterests(data).then(interests => {
                    this.totalInterests = interests.totalCount;
                    this.interests = interests.results;
                    return this.interests;
                })
                    .catch(error => {
                        errorsAlertApi(error);
                        return []
                    })
            },
            handleDelete(id){
                this.$swal({
                    icon: 'warning',
                    text: 'Вы действительно хотите удалить интерес?',
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteInterests(id).then(() => {
                            this.interests = this.interests.filter(request => request._id !== id);
                            this.$refs.interestsTable.refresh();
                        })
                            .catch(error => {
                                errorsAlertApi(error);
                            })
                    }
                })
            },
            handleEdit(interest){
                this.editData = interest;
                this.$bvModal.show('edit-interest');
            }
        },
        data(){
            return{
                isBusy: false,
                interests: [],
                editData: {},
                fields: [
                    {
                        key: 'title',
                        label: 'Название',
                        sortable: true
                    }, {
                        key: 'edit',
                        label: '',
                        tdClass: 'icon-action text-center',
                    }, {
                        key: 'delete',
                        label: '',
                        tdClass: 'icon-action text-center',
                    }
                ],
                currentPage: 1,
                perPage: 10,
                totalInterests: 0,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>