<template>
    <b-modal id="edit-interest" size="lg" centered hide-footer v-model="isShowModal">
        <div class="modal-wrapper">
            <div class="h1">Редактировать интерес</div>
            <interests-form
                :editData="editData"
                :isEdit="true"
                :isShowModal="isShowModal"
                @editInterest="editInterest"
            />
        </div>
    </b-modal>
</template>

<script>
    import InterestsForm from "../forms/interests/InterestForm";
    export default {
        name: "EditInterest",
        props: {
            editData: {
                type: Object,
                required: true
            }
        },
        components: {
            InterestsForm
        },
        methods: {
            editInterest(interest){
                this.$emit('editInterest', interest)
            }
        },
        data(){
            return{
                isShowModal: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h1{
        text-align: center;
    }
    .modal-wrapper{
        max-width: 560px;
        margin: 0 auto;
        text-align: center;
    }
</style>