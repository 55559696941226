<template>
    <ValidationObserver ref="Interest">
        <b-form slot-scope="{ validate }" @submit.prevent="handleSubmit">
            <div class="row">
                <ValidationProvider rules="required|min:2|max:250" name="Название интереса" tag="div" :class="isEdit ? 'col-12' : 'col-xl-4'">
                    <div class="form-group" slot-scope="{ valid, errors }">
                        <b-form-input
                            v-model="title"
                            :state="errors[0] ? false : (valid ? true : null)"
                            placeholder="Введите интерес"
                        ></b-form-input>
                        <b-form-invalid-feedback>
                            {{ errors[0] }}
                        </b-form-invalid-feedback>
                    </div>
                </ValidationProvider>
                <div :class="isEdit ? 'col-12' : 'col-xl-4'">
                    <b-button type="submit" variant="primary submit pl-5 pr-5" :disabled="isBusy" :class="isEdit ? 'w-100 mb-4' : ''">
                        {{ isEdit ? 'Сохранить' : 'Добавить' }}
                        <b-spinner v-if="isBusy" small type="grow"></b-spinner>
                    </b-button>
                </div>
            </div>
        </b-form>
    </ValidationObserver>
</template>

<script>
    import { mapActions } from 'vuex'
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'

    export default {
        name: "InterestsForm",
        props: {
            isEdit: {
                type: Boolean,
                required: true
            },
            editData: {
                type: Object
            },
            isShowModal: {
                type: Boolean
            }
        },
        methods: {
            ...mapActions([
                'addInterest',
                'editInterest'
            ]),
            async handleSubmit() {
                const isValid = await this.$refs.Interest.validate();
                if (isValid) {
                    this.isBusy = true;

                    if(this.isEdit){
                        const data = {
                            interestId: this.editData._id,
                            title: this.title
                        };
                        this.editInterest(data).then(interest => {
                            this.$emit('editInterest', interest);
                        })
                            .catch(error => {
                                errorsAlertApi(error);
                            })

                            .finally(() => {
                                this.isBusy = false;
                            })
                    } else {
                        this.addInterest(this.title).then(() => {
                            this.$emit('updateInterest');

                            this.title = null;
                            this.$nextTick(() => {
                                this.$refs.Interest.reset();
                            });
                        })
                            .catch(error => {
                                errorsAlertApi(error);
                            })

                            .finally(() => {
                                this.isBusy = false;
                            })
                    }
                }
            }
        },
        watch: {
            isShowModal(value){
                if(value){
                    this.title = this.editData.title
                }
            }
        },
        data(){
            return{
                isBusy: false,
                title: null
            }
        }
    }
</script>

<style scoped>

</style>